import React, { useCallback } from 'react'
import { RmcMfeLoader } from '@jarvis/react-mfe-component'
import { MfePropsType } from '../../types/mfeProps'

export const GlobalHeader = (props: MfePropsType) => {
  const SubMfe = useCallback(
    ({ component, title, type }) => {
      return (
        <RmcMfeLoader
          disableContainerPadding
          {...props}
          {...{
            component,
            title,
            type
          }}
        />
      )
    },
    [props]
  )

  return (
    <SubMfe
      type="headerTitle"
      component="@jarvis/react-ecp-header"
    />
  )
}
