import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  padding: ${primitives.layout.size6}px;

  // flex
  display: flex;
  flex-direction: column;

  .vn-tab-list__label {
    align-items: center;
    justify-content: center;
  }
`
