import React, { useCallback, useState } from 'react'
import { Container } from './styles'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import Tabs from '@veneer/core/dist/scripts/tabs/tabs'
import { RmcMfeLoader } from '@jarvis/react-mfe-component'
import { GlobalHeader } from '../../shared-components/GlobalHeader'
import {
  PROXIES_MFE,
  PROXIES_MFE_TYPE,
  PROXY_SETTINGS_MFE,
  PROXY_SETTINGS_MFE_TYPE
} from '../../utils/constants'

const ManageProxies = ({ ...props }) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const [selectedTabId, setSelectedTabId] = useState<number>(0)

  const TabMfe = useCallback(
    ({ component, type }) => {
      return (
        <RmcMfeLoader
          {...props}
          type={type}
          component={component}
        />
      )
    },
    [props]
  )
  const tabs = [
    {
      id: 0,
      label: t('ecp-fleet-proxy-management.proxiesTabLabel', 'Proxies'),
      content: (
        <TabMfe
          type={PROXIES_MFE_TYPE}
          component={PROXIES_MFE}
        />
      )
    },
    {
      id: 1,
      label: t(
        'ecp-fleet-proxy-management.proxySettingsTabLabel',
        'Data Collection Preferences'
      ),
      content: (
        <TabMfe
          type={PROXY_SETTINGS_MFE_TYPE}
          component={PROXY_SETTINGS_MFE}
        />
      )
    }
  ]

  return (
    <>
      <GlobalHeader data-testid="proxy-component-global-header" />
      <Container data-testid="proxy-component-container">
        <Tabs
          controlId="extended"
          mode="extended"
          onChangeTab={(id) => setSelectedTabId(id)}
          selectedTabId={selectedTabId}
          tabs={tabs}
          data-testid="fleet-proxy-tabs"
        />
      </Container>
    </>
  )
}
export default ManageProxies
